import React, { useEffect, useState } from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { API_URL, API_USERNAME, API_PASSWORD } from "../env";
import { encode } from "js-base64";
import './classification.css'
import Typography from "@mui/material/Typography";

interface ClassificationInfo {
  category: string;
  description: string;
  note: string;
  procedure: string[];
}

interface DataType {
  id: string;
  type: string;
  classification: number;
}
const Classification = () => {
  const [classificationInfo, setClassificationInfo] = useState<ClassificationInfo>({
    category: "",
    description: "",
    note: "",
    procedure: [],
  });
  const [selectedLabels, setSelectedLabels] = useState<DataType[]>([]);
  const [error, setError] = useState(null);
  const [labels, setLabels] = useState<DataType[]>([]);
  const [highestClassification, setHighestClassification] = useState<string>("");

  useEffect(() => {
    const fetchLabels = async (path: string) => {
      try {
        const response = await fetch(`${API_URL}/${path}`, {
          method: "GET",
          headers: new Headers({
            Authorization: "Basic " + encode(API_USERNAME + ":" + API_PASSWORD),
            "Content-Type": "application/json",
          }),
        });
        if(response.ok) {
          const data = await response.json();
          setLabels(data.results);
        }
        else {
          throw new Error("Failed to fetch Labels");
        }
      } catch (error) {
        setError(error);
      }
    }
    fetchLabels(`dataTypes`);
  },[])

  useEffect(() => {
    
    const fetchData = async (path: string) => {
      try {
        const response = await fetch(`${API_URL}/${path}`, {
          method: "GET",
          headers: new Headers({
            Authorization: "Basic " + encode(API_USERNAME + ":" + API_PASSWORD),
            "Content-Type": "application/json",
          }),
        });
        if(response.ok) {
          const data = await response.json();
          setClassificationInfo(data.results);
        }
        else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setError(error);
      }
    }

    fetchData(`dataCategory?classification=${highestClassification}`).catch(setError);
  }, [highestClassification]);

  

  const textWithBreaks = (description: string) => {
    if(!description) return null;
    return description.split("\n").map((text: string, index: number) => {
      return (
        <div key={index}>
          {text} <br />
        </div>
      );
    });
  }

  const getClassificationElement = () => {
    if (error) return <pre>{JSON.stringify(error)}</pre>;
    if (classificationInfo.category === "") {
      return (
        <div className="info">
          <strong>
            <em>Please check all the desired data types from the list on the left</em>
          </strong>
        </div>
      );
    }
  
    const getColor = (category: string) => {
      if (category === "High-Risk data") {
        return "color-red";
      } else if (category === "Moderate-Risk data") {
        return "color-orange";
      } else {
        return "color-green";
      }
    };
    const textColor = getColor(classificationInfo.category);
    return (
      <div>
        <Typography sx={{ color: textColor }} align="center">
          {classificationInfo.category}
        </Typography>
        <div className={textColor}>
          {textWithBreaks(classificationInfo.description)}
          <br />
        </div>
        <ol>
          {classificationInfo.procedure &&
            classificationInfo.procedure.map(
              (step: string, stepIndex: number) => (
                <li key={stepIndex}>{step}</li>
              )
            )}
        </ol>
        <p className={textColor }>{classificationInfo.note}</p>
        <hr />
      </div>
    );
  }
  

  const handleCheckedChange = async (
    event: { target: { name: string; checked: boolean } },
    label: DataType
  ) => {
    // const label = event.target.name;
    const isChecked = event.target.checked;
    const updatedLabels = isChecked
      ? [...selectedLabels, label]
      : selectedLabels.filter((prevLabel) => prevLabel !== label);

    setSelectedLabels(updatedLabels);

    // Find the highest classification among the selected labels
    if (updatedLabels.length === 0) {
      setHighestClassification("");
    } else {
      let maxClassification = 3;
      updatedLabels.forEach((label) => {
        if (label.classification < maxClassification) {
          maxClassification = label.classification;
        }
      });
      setHighestClassification(maxClassification.toString());
    }
  };


  return (
    <div className="container">
      <div className="info">
        <em>
          This tool is designed to determine which Risk Classification is
          appropriate for any combination of data type selected from the
          following list. When mixed data falls into multiple risk categories,
          the highest risk classification across all is provided. Note: This is
          not an exhaustive list of all possible scenarios.
        </em>
      </div>
      <div className="classification-container">
        <div className="checkBox">
          {labels.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  name={item.type}
                  onChange={(event) => handleCheckedChange(event, item)}
                />
              }
              label={item.type}
            />
          ))}
        </div>

        <div className="description">{getClassificationElement()}</div>
      </div>
    </div>
  );
}

export default Classification;