import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import './fonts/Acherus Grotesque Updated/acherus_grotesque_-_bold.otf';
import './fonts/Acherus Grotesque Updated/acherus_grotesque_-_regular.otf';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <App />
  </Router>
);


