function formatDescription(description) {
    // Regular expression to match <a> tags
    const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1.*?>(.*?)<\/a>/gi;
  
    // Replace <a> tags with formatted text
    let formattedText = description.replace(anchorRegex, (match, quote, link, text) => {
      return `${text} (${link})`;
    });
  
    // Replace <br /> tags with a space
    formattedText = formattedText.replace(/<br\s*\/?>/gi, ' ');
  
    // Replace commas with semicolons
    formattedText = formattedText.replace(/,/g, ';');
  
    return formattedText;
  }

  export let convertToCSV = (data, conditions, tableName) => {
    let name = 'Selected Scopes: ';

    name += tableName.sort().join(';');
    name += '\n';

    let header = 'Control/Safeguard,Link';

    // Create an array to hold the header items you'll include conditionally
    let conditionalHeaders = [
        'Low-Risk', 'Moderate-Risk', 'High-Risk', 'Network Infrastructure', 'Workforce / IT Users'
    ];

    // Loop through conditions and push respective header if condition is true
    conditions.forEach((condition, index) => {
        if (condition) {
            header += ',' + conditionalHeaders[index];
        }
    });

    header += ',"Safeguard Implementation","Description"\n';


    let rows = data.map(obj => {
        let shouldIncludeRow = false;
        let maturity = '';
        if (obj.maturity === 1) {
            maturity = "required";
        } else if (obj.maturity === 2) {
            maturity = "partial";
        } else if (obj.maturity === 3) {
            maturity = "optional";
        }
        let low = obj.classification === "Low" ? maturity : "N";
        let moderate =
          obj.classification === "Low" || obj.classification === "Moderate"
            ? maturity
            : "N";
        let high =
          obj.classification === "Low" ||
          obj.classification === "Moderate" ||
          obj.classification === "High"
            ? maturity
            : "N";
        let networkInfrastructure = obj.scopes.includes(
          "Network Infrastructure"
        )
          ? maturity
          : "N";
        let workforce = obj.scopes.includes("Workforce / IT Users")
          ? maturity
          : "N";

        conditions.forEach((condition, index) => {
            if (condition) {
                switch (index) {
                    case 0:
                        if (low !== 'N') {
                            shouldIncludeRow = true;
                        }
                        break;
                    case 1:
                        if (moderate !== 'N') {
                            shouldIncludeRow = true;
                        }
                        break;
                    case 2:
                        if (high !== 'N') {
                            shouldIncludeRow = true;
                        }
                        break;
                    case 3:
                        if (networkInfrastructure !== 'N') {
                            shouldIncludeRow = true;
                        }
                        break;
                    case 4:
                        if (workforce !== 'N') {
                            shouldIncludeRow = true;
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        if (shouldIncludeRow) {
            // Prepare the row based on conditions
            let row = `"${obj.index} ${obj.title}","${obj.link}"`;

            conditions.forEach((condition, index) => {
                if (condition) {
                    switch (index) {
                        case 0:
                            row += `,${low}`;
                            break;
                        case 1:
                            row += `,${moderate}`;
                            break;
                        case 2:
                            row += `,${high}`;
                            break;
                        case 3:
                            row += `,${networkInfrastructure}`;
                            break;
                        case 4:
                            row += `,${workforce}`;
                            break;
                        default:
                            break;
                    }
                }
            });

            row += `,"${obj.responsibility}","${formatDescription(obj.description)}"`;

            return row;
        } else {
            return null; // Skip the row
        }
    }).filter(row => row !== null).join('\n'); // Filter out null rows and join the valid rows

    return name + header + rows;
};
