import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
  faThreads,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight, faRibbon } from "@fortawesome/free-solid-svg-icons";
import styles from "./Footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer role="contentinfo">
      <div className={styles["footer-container"]}>
        <div className={styles["vt-footer-row"]}>
          <div className={styles["vt-footer-info"]}>
            <div className={styles["vt-embed-map"]}>
              <img
                className={styles["vt-map-image"]}
                src="https://www.assets.cms.vt.edu/images/vt-campuses.svg"
                alt="Map of Virginia with pin showing locations of Virginia Tech campuses. See All Locations link which follows provides details"
              />
            </div>
            <div className={styles["vt-footer-infoWrapper"]}>
              <div className={styles["vt-footer-logoWrapper"]}>
                <img
                  className={styles["vt-footer-logo"]}
                  src="https://www.assets.cms.vt.edu/images/logo-white-black.svg"
                  alt="Virginia Tech logo"
                />
              </div>
              <div className={styles["footer-links"]}>
                <p className={styles["vt-footer-directions"]}>
                  <a
                    className={styles["footer-info-link"]}
                    href="https://www.vt.edu/maps/directions.html"
                  >
                    Get Directions
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={styles["icon-spacing"]}
                    />
                  </a>
                </p>
                <p className={styles["vt-footer-locations"]}>
                  <a
                    className={styles["footer-info-link"]}
                    href="https://www.vt.edu/maps.html#locations"
                  >
                    See All Locations
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={styles["icon-spacing"]}
                    />
                  </a>
                </p>
                <p className={styles["vt-footer-contacts"]}>
                  <a
                    className={styles["footer-info-link"]}
                    href="https://www.vt.edu/contacts.html"
                  >
                    Contact Virginia Tech
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={styles["icon-spacing"]}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className={styles["vt-footer-rowSpacer"]}></div>
          <div className={styles["vt-footer-links"]}>
            <ul className={styles["vt-footer-linkscol"]}>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/status.html"
                >
                  University Status
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/principles-of-community.html"
                >
                  Principles of Community
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/privacy.html"
                >
                  Privacy Statement
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/acceptable-use.html"
                >
                  Acceptable Use
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.weremember.vt.edu/"
                >
                  <FontAwesomeIcon
                    icon={faRibbon}
                    className={styles["icon-spacing-alt"]}
                  />
                  We Remember
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://lib.vt.edu"
                >
                  University Libraries
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/accessibility.html"
                >
                  Accessibility
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://pip.vt.edu/analytics-gdpr-4/public-disclosures-requirements/student-consumer-information.html"
                >
                  Consumer Information
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/admissions/cost-and-aid.html"
                >
                  Cost & Aid
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://safe.vt.edu/"
                >
                  SAFE at VT
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://policies.vt.edu"
                >
                  Policies
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/equal-opportunity.html"
                >
                  Equal Opportunity
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.wvtf.org"
                >
                  WVTF
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.bkstr.com/virginiatechstore/home"
                >
                  University Bookstore
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://jobs.vt.edu"
                >
                  Jobs at Virginia Tech
                </a>
              </li>
              <li>
                <a
                  className={styles["vt-footer-link"]}
                  href="https://www.vt.edu/strategic-plan.html"
                >
                  Strategic Plan
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`${styles["vt-footer-row"]} ${styles["vt-footer-bottom"]}`}
        >
          <div className={styles["vt_footer_copyright"]}>
            <p className={styles["vt-copyright"]}>
              © 2024 Virginia Polytechnic Institute and State University. All
              rights reserved.
            </p>
          </div>
          <ul className={styles["follow-virginia-tech-icons"]}>
            <li>
              <a
                href="https://facebook.com/virginiatech"
                target="_blank"
                aria-label="Facebook"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/virginia_tech"
                target="_blank"
                aria-label="X"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://www.threads.net/@virginia.tech"
                target="_blank"
                aria-label="Threads"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faThreads} />
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/virginia.tech"
                target="_blank"
                aria-label="Instagram"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="http://www.linkedin.com/edu/schoolvid=19811&trk"
                target="_blank"
                aria-label="LinkedIn"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/VirginiaTech"
                target="_blank"
                aria-label="YouTube"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
