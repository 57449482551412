import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { convertToCSV } from './convertToCSV';
import Button from '@mui/material/Button';
import HtmlReactParser from 'html-react-parser';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { API_USERNAME, API_PASSWORD, API_URL } from "../env";
import { encode } from "base-64";
import styles from "./Table.module.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className, arrow: "arrow" }}>
    <span>{props.children}</span>
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#d2d7dc",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    position: "relative", // Ensure relative positioning for the arrow
  },
  ".arrow": {
    "&::before": {
      backgroundColor: "#d2d7dc", // Set the arrow background color to match the tooltip background color
    },
  },
}));

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#861F41",
    fontFamily: "Acherus Grotesque Regular",
    color: theme.palette.common.white,
    textAlign: "center", // Align header text to center
    border: "1px solid black",
    position: "sticky",
    top: 0,
    zIndex: 1, // Ensure it stays on top of other content
  },
  body: {
    fontSize: 12,
    fontFamily: "Acherus Grotesque Regular",
    border: "1px solid black",
    padding: "4px",
  },
}))(TableCell);


const tableStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflow: "auto",
    border: "4px solid black",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 200px)", // Adjust height as needed
    overflow: "auto",
  },
  table: {
    minWidth: 700,
  },
  customTableContainer: {
    overflowX: "initial",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  checked: {},
  track: {},
  headerCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  grayedColumn: {
    color: theme.palette.text.disabled, // You can customize this color
    pointerEvents: "none",
    backgroundColor: "gray",
  },
  lowRisk: {
    backgroundColor: "#92D050",
    color: "black",
  },
  moderateRisk: {
    backgroundColor: "#FFFF00",
    color: "black",
  },
  highRisk: {
    backgroundColor: "#E10000",
    color: "black",
  },
  lowColumn: {
    backgroundColor: "#C6E49C",
  },
  moderateColumn: {
    backgroundColor: "#FFFF8F",
  },
  highColumn: {
    backgroundColor: "#FF8F8F",
  },
  controlHeader: {
    backgroundColor: "#75787B",
    color: "white",
  },
  localResponsibilityHeader: {
    backgroundColor: "#4BACC6",
  },
  sharedResponsibilityHeader: {
    backgroundColor: "#8064A2",
  },
  doitResponsibilityHeader: {
    backgroundColor: "#F79646",
  },
  localResponsibility: {
    backgroundColor: "#B7DEE8",
  },
  sharedResponsibility: {
    backgroundColor: "#CCC0DA",
  },
  doitResponsibility: {
    backgroundColor: "#FCD5B4",
  },
  switchBaseUnchecked: {
    color: "black",
  },
  switchBaseChecked: {
    color: "white",
  },
  switchTrackUnchecked: {
    backgroundColor: "black",
  },
  switchTrackChecked: {
    backgroundColor: "white",
  },
}); 

function CustomizedTable(props) {

  const [dataSwitch, setDataSwitch] = useState(true);
  const [serverSwitch, setServerSwitch] = useState(true);
  const [endpointSwitch, setEndPointSwitch] = useState(true);
  const [applicationSwitch, setApplicationSwitch] = useState(true);
  const [networkSwitch, setNetworkSwitch] = useState(true);
  const [workForceSwitch, setWorkForceSwitch] = useState(true);

  const [low, setLow] = useState(true);
  const [moderate, setModerate] = useState(true);
  const [high, setHigh] = useState(true);

  const [rowdata, setRowData] = useState([])

  useEffect(() => {
    const apiUrl = {
      data: `safeguards?scope=Data`,
      servers: `safeguards?scope=Servers`,
      endpoints: `safeguards?scope=Endpoints`,
      applications: `safeguards?scope=Applications`,
      networkInfrastructure:
        `safeguards?scope=Network Infrastructure`,
      workforce:
        `safeguards?scope=Workforce / IT Users`,
    };

    const fetchApiData = async (path) => {
      const response = await fetch(`${API_URL}/${path}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Basic " + encode(API_USERNAME + ":" + API_PASSWORD),
          "Content-Type": "application/json",
        }),
      });
      const data = await response.json();
      return data;
    }
    const fetchData = () => {
      const selectedApiUrls = [];

      if (dataSwitch) selectedApiUrls.push("data");
      if (serverSwitch) selectedApiUrls.push("servers");
      if (endpointSwitch) selectedApiUrls.push("endpoints");
      if (applicationSwitch) selectedApiUrls.push("applications");
      if (networkSwitch) selectedApiUrls.push("networkInfrastructure");
      if (workForceSwitch) selectedApiUrls.push("workforce");

      const fetchDataPromises = selectedApiUrls.map((api) => fetchApiData(apiUrl[api]));

      Promise.all(fetchDataPromises)
        .then((results) => {
          const combinedResults = results.flat();
          const allResults = combinedResults.flatMap((item) => item.results);

          const customSort = (a, b) => {
            const partsA = a.index.split(".").map((part) => parseInt(part, 10));
            const partsB = b.index.split(".").map((part) => parseInt(part, 10));

            if (partsA[0] === partsB[0]) {
              return partsA[1] - partsB[1];
            } else {
              return partsA[0] - partsB[0];
            }
          };
          // Sort the uniqueResults array based on the index property
          const sortedResults = allResults.sort(customSort);

          const uniqueResults = [];

          // Loop through sortedResults to get unique results
          sortedResults.forEach((result) => {
            if (!uniqueResults.some((r) => r.index === result.index)) {
              uniqueResults.push(result);
            }
          });

          setRowData(uniqueResults);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setRowData([]);
        });
    };
    fetchData();
  }, [
    dataSwitch,
    serverSwitch,
    endpointSwitch,
    applicationSwitch,
    networkSwitch,
    workForceSwitch,
  ]);

  const { classes } = props;

  const renderMaturiy = (maturity) => {
    if (maturity === 1) {
      return (
        <Typography sx={{ fontSize: "large", fontWeight: "bold" }}>
          <DoneIcon />
        </Typography>
      );
    } else if (maturity === 2) {
      return (
        <Typography sx={{ fontSize: "large", fontWeight: "bold" }}>
          <AddIcon />
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ fontSize: "large", fontWeight: "bold" }}>
          <CircleOutlinedIcon />
        </Typography>
      );
    }
  }
  const renderStatus = (value, fetchedValue, maturity) => {
    if(fetchedValue === 'Low') {
      return renderMaturiy(maturity);
    }
    if(fetchedValue === 'Moderate') {
      if(value === 'Moderate' || value === 'High') {
        return renderMaturiy(maturity);
      }
    }
    if(fetchedValue === 'High') {
      if(value === 'High') {
        return renderMaturiy(maturity);
      }
    }
    return <span></span>;
  };

  const renderScope = (value, fetchedList, maturity) => {
    if(fetchedList.includes(value)) {
      return renderMaturiy(maturity);
    }
    else {
      return <span></span>;
    }
  }

  const checkRiskValue = (value, fetchedValue) => {
    if(fetchedValue === 'Low') {
      return true;
    }
    if(fetchedValue === 'Moderate') {
      if(value === 'Moderate' || value === 'High') {
        return true;
      }
    }
    if(fetchedValue === 'High') {
      if(value === 'High') {
        return true;
      }
    }
    return false;
  }

  const checkScopeValue = (value, fetchedList) => {
    return fetchedList.includes(value);
  }

  const renderResponsibility = (value, fetchedValue) => {
    if(fetchedValue === value) {
      return <span id="tick">&#10003;</span>;
    }
    else {
      return <span></span>;
    }
  }
  // Handle the toggle state change 
  const handleDataChange = (event) => {
    setDataSwitch(event.target.checked);
  };

  const handleServerChange = (event) => {
    setServerSwitch(event.target.checked);
  };

  const handleEndPointChange = (event) => {
    setEndPointSwitch(event.target.checked);
  };

  const handleApplicationChange = (event) => {
    setApplicationSwitch(event.target.checked);
  };

  const handleNetworkChange = (event) => {
    setNetworkSwitch(event.target.checked);
  };

  const handleWorkForceChange = (event) => {
    setWorkForceSwitch(event.target.checked);
  };

  const handleLowChange = (event) => {
    setLow(event.target.checked);
  };

  const handleModerateChange = (event) => {
    setModerate(event.target.checked);
  };

  const handleHighChange = (event) => {
    setHigh(event.target.checked);
  };

  const renderSwitch = (value, onChange) => {
    return (
      <Switch
        checked={value}
        onChange={onChange}
        classes={{
          switchBase: value
            ? classes.switchBaseChecked
            : classes.switchBaseUnchecked,
          track: value
            ? classes.switchTrackChecked
            : classes.switchTrackUnchecked,
          thumb: value
            ? classes.switchBaseChecked
            : classes.switchBaseUnchecked,
        }}
      />
    );
  };

  const handleDownload = () => {
    // Convert the table data to CSV format
    let conditions = [ low, moderate, high, networkSwitch, workForceSwitch];
    if(!dataSwitch && !endpointSwitch && !serverSwitch && !applicationSwitch) {
      conditions = [ false, false, false, networkSwitch, workForceSwitch];
    }
    let tableName = [];
    if(dataSwitch) { tableName.push("Data"); }
    if(endpointSwitch) { tableName.push("Endpoints"); }
    if(serverSwitch) { tableName.push("Servers"); }
    if(applicationSwitch) { tableName.push("Applications"); }
    if(networkSwitch) { tableName.push("Network Infrastructure"); }
    if(workForceSwitch) { tableName.push("Workforce / IT Users"); }

    const csv = convertToCSV(rowdata, conditions, tableName);

    // Create a blob and initiate the download
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    
    const timestamp = Date.now();
    // Convert the timestamp to a readable date string
    const currentDate = new Date(timestamp).toLocaleString();

    link.setAttribute('download', `VT_Minimum Security Standard v4.0_${currentDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dataText = 'Discrete facts/values that convey information related to university business.  When mixed data falls into multiple risk categories, use the highest risk classification across all.';
  const endPointsText = 'Desktops, laptops, or mobile devices.';
  const serverText = 'Hosts that provide network-accessible services.  Servers can be physical or virtual machines and may be hosted on-premises or with a cloud service provider (CSP).';
  const applicationText = 'Software programs, code, or packages that perform specific functions directly for end users or for other applications.  Applications can be “self-contained” or groups of programs and may or may not be network accessible.  Applications can be developed and maintained “in-house” by VT units, or provided by a 3rd party service provider.';
  const networkInfrastructureText = 'Devices that transport communications needed for data, devices, applications, services, and multi-media.  This includes devices such as routers, switches, load-balancers, wireless access points, firewalls, intrusion detection/prevention systems, and other security or special purpose appliances.';
  const workforceText = 'University workforce personnel who work with university data and utilize technology resources to perform their job duties.';

  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.clientHeight;
      setHeaderHeight(height);
    }
  }, []);

  let displayedControls = []

  return (
    <div>
      <div className={styles.flexContainer}>
        <div>
          <em>
            Please use the toggle buttons below to filter the data according to
            your choices. After setting your preferences, you can download the
            filtered data in CSV format using the "Download" button below.
          </em>
        </div>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownload}
          padding="1px"
        >
          Download
        </Button>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <CustomTableCell rowSpan="2" colSpan="2" align="center">
                  Control/Safeguard
                </CustomTableCell>
                <CustomTableCell ref={headerRef} colSpan="3" align="center">
                  <div className={styles.headerCell}>
                    <div className={styles.switchContainer}>
                      <LightTooltip title={dataText} arrow>
                        <span>Data</span>
                      </LightTooltip>
                      {renderSwitch(dataSwitch, handleDataChange)}
                    </div>
                    <div className={styles.switchContainer}>
                      <LightTooltip title={endPointsText} arrow>
                        <span>Endpoints</span>
                      </LightTooltip>
                      {renderSwitch(endpointSwitch, handleEndPointChange)}
                    </div>
                    <div className={styles.switchContainer}>
                      <LightTooltip title={serverText} arrow>
                        <span>Servers</span>
                      </LightTooltip>
                      {renderSwitch(serverSwitch, handleServerChange)}
                    </div>
                    <div className={styles.switchContainer}>
                      <LightTooltip title={applicationText} arrow>
                        <span>Applications</span>
                      </LightTooltip>
                      {renderSwitch(applicationSwitch, handleApplicationChange)}
                    </div>
                  </div>
                </CustomTableCell>
                <CustomTableCell rowSpan="2" align="center">
                  <div className={styles.switchContainer}>
                    <LightTooltip title={networkInfrastructureText} arrow>
                      <span>Network Infrastructure</span>
                    </LightTooltip>
                    {renderSwitch(networkSwitch, handleNetworkChange)}
                  </div>
                </CustomTableCell>
                <CustomTableCell rowSpan="2" align="center">
                  <div className={styles.switchContainer}>
                    <LightTooltip title={workforceText} arrow>
                      <span>Workforce IT Users</span>
                    </LightTooltip>
                    {renderSwitch(workForceSwitch, handleWorkForceChange)}
                  </div>
                </CustomTableCell>

                <CustomTableCell colSpan="3" align="center">
                  Safeguard Implementation
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell
                  align="center"
                  className={`${classes.lowRisk}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  Low-Risk {renderSwitch(low, handleLowChange)}
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={`${classes.moderateRisk}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  Moderate-Risk {renderSwitch(moderate, handleModerateChange)}
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={`${classes.highRisk}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  High-Risk {renderSwitch(high, handleHighChange)}
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={`${classes.localResponsibilityHeader}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  Local
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={`${classes.sharedResponsibilityHeader}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  Shared
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={`${classes.doitResponsibilityHeader}`}
                  style={{
                    top: `${headerHeight + 1}px`,
                  }}
                >
                  DoIT
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowdata.length ? (
                rowdata.map((row) => (
                  <React.Fragment key={row.index}>
                    {!displayedControls.includes(row.control) &&
                      ((low && checkRiskValue("Low", row.classification)) ||
                        (moderate &&
                          checkRiskValue("Moderate", row.classification)) ||
                        (high && checkRiskValue("High", row.classification)) ||
                        (networkSwitch &&
                          checkScopeValue(
                            "Network Infrastructure",
                            row.scopes
                          )) ||
                        (workForceSwitch &&
                          checkScopeValue(
                            "Workforce / IT Users",
                            row.scopes
                          ))) &&
                      (displayedControls.push(row.control),
                      (
                        <TableRow className={classes.row}>
                          <CustomTableCell
                            colSpan="10"
                            className={classes.controlHeader}
                          >
                            {row.control}
                          </CustomTableCell>
                        </TableRow>
                      ))}

                    {(low && checkRiskValue("Low", row.classification)) ||
                    (moderate &&
                      checkRiskValue("Moderate", row.classification)) ||
                    (high && checkRiskValue("High", row.classification)) ||
                    (networkSwitch &&
                      checkScopeValue("Network Infrastructure", row.scopes)) ||
                    (workForceSwitch &&
                      checkScopeValue("Workforce / IT Users", row.scopes)) ? (
                      <TableRow className={classes.row}>
                        <CustomTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {row.index}
                        </CustomTableCell>
                        <CustomTableCell component="th" scope="row">
                          <a href={row.link} target="_blank" rel="noreferrer">
                            <LightTooltip
                              title={HtmlReactParser(row.description)}
                              arrow
                            >
                              {row.title}
                            </LightTooltip>
                          </a>
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={
                            (dataSwitch ||
                              endpointSwitch ||
                              serverSwitch ||
                              applicationSwitch) &&
                            low
                              ? classes.lowColumn
                              : classes.grayedColumn
                          }
                        >
                          {(dataSwitch ||
                            endpointSwitch ||
                            serverSwitch ||
                            applicationSwitch) &&
                          low
                            ? renderStatus(
                                "Low",
                                row.classification,
                                row.maturity
                              )
                            : ""}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={
                            (dataSwitch ||
                              endpointSwitch ||
                              serverSwitch ||
                              applicationSwitch) &&
                            moderate
                              ? classes.moderateColumn
                              : classes.grayedColumn
                          }
                        >
                          {(dataSwitch ||
                            endpointSwitch ||
                            serverSwitch ||
                            applicationSwitch) &&
                          moderate
                            ? renderStatus(
                                "Moderate",
                                row.classification,
                                row.maturity
                              )
                            : ""}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={
                            (dataSwitch ||
                              endpointSwitch ||
                              serverSwitch ||
                              applicationSwitch) &&
                            high
                              ? classes.highColumn
                              : classes.grayedColumn
                          }
                        >
                          {(dataSwitch ||
                            endpointSwitch ||
                            serverSwitch ||
                            applicationSwitch) &&
                          high
                            ? renderStatus(
                                "High",
                                row.classification,
                                row.maturity
                              )
                            : ""}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={networkSwitch ? "" : classes.grayedColumn}
                        >
                          {networkSwitch
                            ? renderScope(
                                "Network Infrastructure",
                                row.scopes,
                                row.maturity
                              )
                            : ""}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={
                            workForceSwitch ? "" : classes.grayedColumn
                          }
                        >
                          {workForceSwitch
                            ? renderScope(
                                "Workforce / IT Users",
                                row.scopes,
                                row.maturity
                              )
                            : ""}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={classes.localResponsibility}
                        >
                          {renderResponsibility("Local", row.responsibility)}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={classes.sharedResponsibility}
                        >
                          {renderResponsibility("Shared", row.responsibility)}
                        </CustomTableCell>
                        <CustomTableCell
                          align="center"
                          className={classes.doitResponsibility}
                        >
                          {renderResponsibility("DoIT", row.responsibility)}
                        </CustomTableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="10">Loading...</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyles)(CustomizedTable);
