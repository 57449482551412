import './App.css';
import Header from './Header.js'
import NavBar from './NavBar.js'
import Appendix from "./Appendix.js";
import Table from './MinimumSecurityStandard/Table.js'
import Footer from './components/Footer/Footer.tsx'
import Classification from './DataClassification/Classification.tsx'
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <NavBar /> 
      <Routes>
        <Route path="/" element={<Table />} />
        <Route path="/dataClassification" element={<Classification />} />
      </Routes>
      <Appendix />
      <Footer />
    </div>
  );
}

export default App;
